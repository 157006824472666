<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.history") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="listOfServiceOrderHistories">
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="serviceOrderHistory in listOfServiceOrderHistories"
        :key="serviceOrderHistory.id"
      >
        <div
          class="clebex-item-content-wrapper"
          style="display: flex; flex-direction: column; padding: 0.6rem 0rem;"
        >
          <div class="clebex-section-input" style="padding: 0px;">
            <label class="clebex-section-input-label" for="description">{{
              displayLabelName("services.history.datetime")
            }}</label>
            <span class="history-item">
              {{ serviceOrderHistory.datetime }}</span
            >
          </div>
          <div class="clebex-section-input" style="padding: 0px;">
            <label class="clebex-section-input-label" for="description">{{
              displayLabelName("services.history.user")
            }}</label>
            <span class="history-item"> {{ serviceOrderHistory.user }}</span>
          </div>
          <div class="clebex-section-input" style="padding: 0px;">
            <label class="clebex-section-input-label" for="description">{{
              displayLabelName("services.history.action")
            }}</label>
            <span class="history-item"> {{ serviceOrderHistory.action }}</span>
          </div>
          <div class="clebex-section-input" style="padding: 0px;">
            <label class="clebex-section-input-label" for="description">{{
              displayLabelName("services.history.details")
            }}</label>
            <span class="history-item"> {{ serviceOrderHistory.details }}</span>
          </div>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "UpdateStatus",
  data() {
    return {};
  },
  created() {
    this.getServiceOrderHistory(this.$route.params.service_order_id);
  },
  computed: {
    ...mapState("service", ["serviceOrderHistory"]),
    listOfServiceOrderHistories() {
      if (
        this.serviceOrderHistory &&
        this.serviceOrderHistory.data &&
        this.serviceOrderHistory.data.length
      ) {
        return this.serviceOrderHistory.data;
      }

      return null;
    }
  },
  methods: {
    ...mapActions("service", ["getServiceOrderHistory"]),
    checked(name) {
      return this.orderStatus == name;
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.history-item {
  color: #999999;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.3;
  font-size: 16px;
}
</style>
